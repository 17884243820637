import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { CodeBlock } from "../commonComponents"


export default function SendCommentsDocs() {

  const json = {
   "result": "success"
}

  return (
    <Layout
      keywords="IrisAgent Send Comments API guide"
      title="Use API to send ticket comments"
      description="Documentation on how to use IrisAgent's API to send ticket comments to IrisAgent."
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/send-comments-api-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Send Comments API Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Send Comments API</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>
                    <h1>Send Comments API Documentation</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    Use this API to send ticket comments from your ticketing system to IrisAgent to allow us to do AI processing of ticket data.
                    <br/>
                    <br/>
                    <h2>
                    API Guide
                    </h2>
                    <br/>
                    Below is a <span style={{"font-family": "Consolas"}}>curl</span> command to call this API:
                    <CodeBlock language="javascript" value={`
curl -X POST --location "https://api1.irisagent.com/v1/send-comments" \\
    -H "Content-Type: application/json" \\
    -H "Authorization: Bearer Replace_me_business_id" \\
    -d '{
        "data":[
          {"conversationId": "12345",
          "subject": "Application will not access Flight Log",
          "description": "When clicking on Flight Log the application keeps producing an ERROR message and will not allow access or Flight Log updating. ",
          "status": "open",
          "comments":[
              {
                  "commentId": 123e4567-e89b-12d3-a456-426655440000,
                  "comment": "Please help to fix this ASAP.",
                  "commenter": "Customer",
                  "isPublic": true,
                  "timeOfCreation": "1743772260"
              },
              {
                  "commentId": f47ac10b-58cc-4372-a567-0e02b2c3d479,
                  "comment": "Thank you for the information. I have forwarded this issue with all the details to our engineering team for further investigation. I will let you know as soon as any new information becomes available. John Doe | Technical Support Specialist | Acme Systems International, Inc.",
                  "commenter": "Agent",
                  "isPublic": true,
                  "timeOfCreation": "1743772261"
              },
                {
                    "commentId": 550e8400-e29b-41d4-a716-446655440000,
                    "comment": "Billy - Please let the customer know that this issue has been fixed.",
                    "commenter": "Agent",
                    "isPublic": false,
                    "timeOfCreation": "1743772263"
                },
                {
                    "commentId": f81d4fae-7dec-11d0-a765-00a0c91e6bf6,
                    "comment": "I have updated and closed this issue as resolved. While I'm confident we've addressed your issues, if you have any other questions, comments or concerns feel free to create a new ticket. I'm here to help.Please do NOT reply to this email unless you would like us to reopen it. Thank you \n John Doe | Technical Support Specialist O: _ • TF: _\nAcme Systems International, Inc.",
                    "commenter": "Agent",
                    "isPublic": true,
                    "timeOfCreation": "1743772280"
                }
          ]},
      ]
        }'
          `}>
</CodeBlock>
<br/>
The API inputs are as follows,
<br/><br/>
<table style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
    <tr>
        <th>Field</th>
        <th>Description</th>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>conversationId</span></td>
        <td>[Required] Unique ticket or chat id</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>subject</span></td>
        <td>[Required] Initial query by the customer. This could be the subject of the email or the first query by the customer in a chat conversation.</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>status</span></td>
        <td>[Required] The latest status of the ticket. It can accept any value, example values include "open", "closed", etc.</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>description</span></td>
        <td>[Optional] Details on the initial query by the customer. This could be the description of the initial email by the customer. Leave empty for a chat conversation.</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>comments</span></td>
        <td>[Optional] Array of messages between the customer and the agent. Each message should have the following fields:
        <ul>
        <li><span style={{"font-family": "Consolas"}}>commentId</span>: [Required] UUID of the comment to uniquely identify the comment</li>
        <li><span style={{"font-family": "Consolas"}}>comment</span>: [Required] Message content</li>
        <li><span style={{"font-family": "Consolas"}}>commenter</span>: [Required] Author of the message: Either "Customer" or "Agent"</li>
        <li><span style={{"font-family": "Consolas"}}>isPublic</span>: [Optional] Default value is true. Set as false for private comments.</li>
        <li><span style={{"font-family": "Consolas"}}>timeOfCreation</span>: [Required] Epoc time when the comment was created</li>
        </ul>
        </td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>Replace_me_business_id</span></td>
        <td>This is the ID provided by the IrisAgent team</td>
    </tr>
</table>
<br/><br/>
A sample output of the API:
<br/><br/>
<CodeBlock language="json" value={JSON.stringify(json, null, 2)}>
</CodeBlock>

<br/><br/>
How to send ticket updates using this API:
<br/><br/>
<ul>
<li>If only ticket status updated -> Pass Subject, Description and Latest Status.</li>
<li>If only comment(s) updated -> Pass Subject, Description, Current Status and the updated comment data.</li>
<li>If both status and comments are updated -> Similar to second point above. </li>
</ul>

                    </div>
                    </div>
                    </section>

</Layout>
  )
}